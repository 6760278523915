



















































































































import {defineComponent, PropType} from '@vue/composition-api';
import {
  DynamicTable,
  tableComponentMap,
  TableComponentName,
  TableHeader,
  TableRow,
} from '@/tasks/types/DynamicTable';
import VueDraggable from 'vuedraggable';

export default defineComponent({
  name: 'DynamicTableInput',
  components: {VueDraggable},
  mixins: [],
  props: {
    enableEditableHeaders: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    enableDynamicColumns: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    modelValue: {
      type: Object as PropType<DynamicTable>,
      default: () => ({
        headers: [{label: 'Data', component: TableComponentName.STextarea, prop: 'data'}],
        rows: [],
      }),
    },
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  data() {
    const table = JSON.parse(JSON.stringify(this.modelValue)) as DynamicTable;

    if (!table.headers) {
      table.headers = [{label: 'Data', component: TableComponentName.STextarea, prop: 'data'}];
    }

    if (!table.rows) {
      table.rows = [];
    }

    return {
      table,
    };
  },
  computed: {
    tableComponentMap() {
      return tableComponentMap;
    },
  },
  watch: {
    modelValue: {
      handler(newModelValue) {
        const table = JSON.parse(JSON.stringify(newModelValue));

        if (!table.headers || !table.headers.length) {
          table.headers = [{label: 'Data', component: TableComponentName.STextarea, prop: 'data'}];
        }

        if (!table.rows) {
          table.rows = [];
        }

        this.table = table;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    triggerEmit() {
      this.$emit('update:modelValue', this.table);
    },
    addRow(index: number) {
      const newRow: TableRow = {};
      this.table.headers.forEach((header: TableHeader) => {
        newRow[header.prop] = '';
      });
      if (index === -1) {
        this.table.rows.push(newRow);
      } else {
        this.table.rows.splice(index, 0, newRow);
      }
      this.triggerEmit();
    },
    removeRow(index: number) {
      if (window.confirm('Delete this row?')) {
        this.table.rows.splice(index, 1);
        this.triggerEmit();
      }
    },
    generateUniqueColumnProp(numColumns: number) {
      let uniqueIndex = numColumns;
      let propName = `new_column_${uniqueIndex}`;
      while (this.table.headers.some((header) => header.prop === propName)) {
        uniqueIndex++;
        propName = `new_column_${uniqueIndex}`;
      }
      return propName;
    },
    redistributeColumnWidths(numColumns: number) {
      const newColumnWidth = `${100 / numColumns}%`;
      this.table.headers.forEach((header, index) => {
        if (header.width) {
          header.width = `${(parseFloat(header.width) * (numColumns - 1)) / numColumns}%`;
        }
      });
      return newColumnWidth;
    },
    addColumn(index: number) {
      const newColumnPropName = this.generateUniqueColumnProp(this.table.headers.length);
      this.redistributeColumnWidths(this.table.headers.length);

      const newColumn: TableHeader = {
        label: 'New Column',
        component: TableComponentName.STextarea,
        prop: newColumnPropName,
        width: 'auto',
      };

      if (index === -1) {
        this.table.headers.push(newColumn);
      } else {
        this.table.headers.splice(index, 0, newColumn);
      }

      this.table.rows.forEach((row: any) => {
        row[newColumnPropName] = '';
      });
      this.triggerEmit();
    },
    deleteColumn(index: number) {
      if (window.confirm('Delete this column?')) {
        const numColumns = this.table.headers.length;
        let width = this.table.headers[index].width;

        if (width === undefined) {
          width = `${(1 / this.table.headers.length) * 100}`;
        } else {
          width = `${width}`;
        }

        const removedColumnWidth = parseFloat(width);
        const redistributedWidth = removedColumnWidth / numColumns;

        this.table.headers.forEach((header, i) => {
          if (i !== index) {
            if (header.width !== undefined) {
              header.width = `${parseFloat(header.width) + redistributedWidth}%`;
            }
          }
        });

        const propToDelete = this.table.headers[index].prop;
        this.table.headers.splice(index, 1);

        this.table.rows.forEach((row: any) => {
          this.$delete(row, propToDelete);
        });

        this.triggerEmit();
      }
    },
    onEnd(event: any) {
      this.triggerEmit();
    },
  },
});
