import STextarea from '@/common/components/STextarea.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export type TableRow = {
  [key: string]: string | number | boolean | null;
};

export enum TableComponentName {
  STextarea = 'STextarea',
  STextareaShort = 'STextareaShort',
  CalculationInput = 'CalculationInput',
}

export const tableComponentMap = {
  [TableComponentName.STextarea]: STextarea,
  [TableComponentName.STextareaShort]: STextareaShort,
  [TableComponentName.CalculationInput]: CalculationInput,
};

export type TableHeader = {
  label: string;
  component: TableComponentName;
  prop: string;
  width?: string;
};

export type DynamicTable = {
  headers: TableHeader[];
  rows: TableRow[];
};
